import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';

// assets
import logo from './assets/Logos/logo.png';
import logo3C from './assets/Logos/3c.png';
import iconDo from './assets/icon_do.png';
import iconGoal from './assets/icon_goal.png';
import iconPlan from './assets/icon_plan.png';
import iconWhy from './assets/icon_why.png';
import iconCheck from './assets/icon_check.png';

const Fortress = window.cccisd.fortress;

const PlanReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                },
            });

            let queryData = {};
            queryData = result.data.flows.assignmentProgress.devTags;

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    return <>{settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} />}</>;
};

const Header = props => {
    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
        </>
    );
};

const Footer = props => {
    return (
        <div className={style.footer_container}>
            <span className={style.footer_text}>Copyright {new Date().getFullYear()}</span>
            <img src={logo3C} alt="" className={style.footer_img} />
        </div>
    );
};

const AccommodationsGrid = props => {
    const { devTagsData } = props;

    const { U8Goal, U8Why, U8PlanA, U8PlanB, U8Do } = devTagsData;

    const goalContent = (
        <div>
            <div style={{ position: 'relative', left: '12px', top: '8px', fontSize: '14px', fontWeight: '500' }}>
                What is my goal?
            </div>
            <div style={{ padding: '12px' }}>{U8Goal}</div>
        </div>
    );
    const whyContent = (
        <div>
            <div style={{ position: 'relative', left: '12px', top: '8px', fontSize: '14px', fontWeight: '500' }}>
                Why do I want to work toward that goal?
            </div>
            <div style={{ padding: '12px' }}>{U8Why}</div>
        </div>
    );
    const planContent = (
        <div
            style={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridTemplateColumns: '50%',
                background: 'rgb(34, 104, 124)',
                height: '100%',
                gap: '2px',
            }}
        >
            <div
                style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    background: 'white',
                    paddingLeft: '12px',
                    paddingTop: '8px',
                }}
            >
                Plan A
                <div
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        opacity: '.6',
                    }}
                >
                    What are the steps of my plan?
                </div>
                <div
                    style={{
                        fontWeight: '400',
                        border: 'black dashed 1.5px',
                        marginRight: '12px',
                        padding: '5px',
                        marginBottom: '8px',
                    }}
                >
                    {U8PlanA}
                </div>
            </div>
            <div
                style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    background: 'white',
                    paddingLeft: '12px',
                    paddingTop: '8px',
                }}
            >
                Plan B
                <div
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        opacity: '.6',
                    }}
                >
                    What are the steps of my plan?
                </div>
                <div
                    style={{
                        fontWeight: '400',
                        border: 'black dashed 1.5px',
                        marginRight: '12px',
                        padding: '5px',
                        marginBottom: '8px',
                    }}
                >
                    {U8PlanB}
                </div>
            </div>
        </div>
    );
    const doContent = (
        <div>
            <div style={{ position: 'relative', left: '12px', top: '8px', fontSize: '14px', fontWeight: '500' }}>
                {`Don't forget I can use a plan B`}
            </div>
            <div style={{ padding: '12px' }}>{U8Do}</div>
        </div>
    );
    const checkContent = (
        <div>
            <div
                style={{
                    position: 'relative',
                    left: '12px',
                    top: '8px',
                    fontSize: '14px',
                    fontWeight: '500',
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridTemplateColumns: '50%',
                    marginBottom: '10px',
                }}
            >
                <div>Did I accomplish my goal?</div>
                <div style={{ opacity: '.6' }}>YES</div>
                <div style={{ opacity: '.6' }}>NO</div>
            </div>
            <div
                style={{
                    position: 'relative',
                    left: '12px',
                    top: '8px',
                    fontSize: '14px',
                    fontWeight: '500',
                    marginBottom: '15px',
                }}
            >
                <div>How did it go?</div>
                <div style={{ width: '500px', marginTop: '20px', opacity: '.6' }}>
                    <div
                        style={{
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridTemplateColumns: '100px 100px 100px 100px 100px',
                            textAlign: 'center',
                        }}
                    >
                        <div>
                            1<div>Not so good</div>
                        </div>
                        <div>2</div>
                        <div>
                            3<div>OK</div>
                        </div>
                        <div>4</div>
                        <div>
                            5<div>Great</div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '18px' }}>Is there anything I would change about my plan for next time?</div>
                <div style={{ fontWeight: '400', minHeight: '70px', width: '640px' }} />
            </div>
        </div>
    );

    return (
        <>
            <div className={style.green_box_section}>
                <div className={style.section_title}>Goal, Why, Plan, Do, Check</div>
            </div>
            <div className={style.section_grid_container}>
                <GridRow icon={iconGoal} content={goalContent} />
                <GridRow icon={iconWhy} content={whyContent} />
                <GridRow icon={iconPlan} content={planContent} />
                <GridRow icon={iconDo} content={doContent} />
                <GridRow icon={iconCheck} content={checkContent} />
            </div>
        </>
    );
};

const GridRow = props => {
    const { icon, content } = props;
    return (
        <div className={style.category_data_row}>
            <div className={style.icon_column}>
                <img src={icon} alt="" className={style.icon} />
            </div>
            <div style={{ background: 'white', position: 'relative' }}>{content}</div>
        </div>
    );
};

const Page1 = props => {
    const { devTagsData } = props;

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <AccommodationsGrid devTagsData={devTagsData} />
                <Footer />
            </div>
        </Page>
    );
};

PlanReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

PlanReport.defaultProps = {};

export default PlanReport;
